import "./header_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["root", "menu", "container"];

  connect() {
    this.menuTarget.addEventListener("click", this.handleMenu);
  }

  handleMenu = () => {
    this.rootTarget.classList.toggle("c-header-open");
    this.menuTarget.classList.toggle("c-header-cross");
    this.containerTarget.classList.toggle("c-header-is-active");
  };
}
