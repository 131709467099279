import "./suggested_videos_component.css";
import "flickity/dist/flickity.min.css";
import Flickity from "flickity";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["carouselWrapper", "previousButton", "nextButton"];
  flkty = null;

  connect() {
    this.connectFlickity();
  }

  disconnect() {
    this.disconnectFlickity();
  }

  connectFlickity(options) {
    const defaultOptions = {
      autoPlay: false,
      pageDots: false,
      wrapAround: true,
      freeScroll: false,
      groupCells: window.innerWidth <= 576 ? 1 : 3,
      cellAlign: "left",
      prevNextButtons: false,
    };

    this.flkty = new Flickity(this.carouselWrapperTarget, {
      ...defaultOptions,
      ...options,
    });
    // TODO : remove resize and add imagesloaded
    this.flkty.resize();
  }

  disconnectFlickity() {
    if (this.flkty) {
      this.flkty.destroy();
      this.flkty = null;
    }
  }

  previous() {
    this.flkty.previous();
  }

  next() {
    this.flkty.next();
  }
}
