/* eslint-disable */
import "./billboard_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["muteButton", "video"];

  connect() {
    this.player = new Vimeo.Player(this.videoTarget);
    this.muted = true;
    this.updateModifiers();
  }

  handleMute() {
    this.muted = !this.muted;
    this.player.setMuted(this.muted);
    this.updateModifiers();
  }

  updateModifiers() {
    this.muteButtonTarget.classList.toggle("c-billboard-muted", this.muted);
  }
}
