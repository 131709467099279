import "./brand_pill_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["root", "menu"];

  open() {
    if (!this.hasMenuTarget) return;

    this.rootTarget.classList.add("c-brand-pill-open");
  }

  close() {
    if (!this.hasMenuTarget) return;

    this.rootTarget.classList.remove("c-brand-pill-open");
  }
}
