import "./series_drawer_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["filter"];
  static activeClass = "c-series-drawer-active";

  connect() {
    if (this.hasFilterTarget) {
      this.filterTargets[0].classList.add(Controller.activeClass);
    }
  }

  toggle(event) {
    this.filterTargets.forEach((target) => {
      if (target !== event.currentTarget) {
        target.classList.remove(Controller.activeClass);
      }
    });

    if (!event.currentTarget.classList.contains(Controller.activeClass)) {
      event.currentTarget.classList.toggle(Controller.activeClass);
    }
  }
}
