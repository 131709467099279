/* eslint-disable */
import "./video_player_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["muteButton", "video"];
  static values = {
    provider: String,
  };

  connect() {
    switch (this.providerValue) {
      case "YouTube":
        this.connectYoutube();
        break;
      case "Vimeo":
        this.connectVimeo();
        break;
    }
    this.muted = true;
    this.updateModifiers();
  }

  connectVimeo(e) {
    this.player = new Vimeo.Player(this.videoTarget);
  }

  connectYoutube() {
    if (typeof YT === "undefined" || typeof YT.Player === "undefined") {
      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady.bind(this);
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      this.onYouTubeIframeAPIReady();
    }
  }

  onYouTubeIframeAPIReady() {
    this.player = new YT.Player(this.videoTarget, {
      events: {
        onReady: this.onYoutubePlayerReady.bind(this),
      },
    });
  }

  onYoutubePlayerReady(e) {
    this.player.setVolume(0);
  }

  updateModifiers() {
    this.muteButtonTarget.classList.toggle("c-video-player-muted", this.muted);
  }

  handleMute() {
    this.muted = !this.muted;
    switch (this.providerValue) {
      case "Vimeo":
        if (this.player) {
          this.player.setMuted(this.muted);
        }
        break;
      case "YouTube":
        if (this.player) {
          this.player.setVolume(this.muted ? 0 : 100);
        }
        break;
    }
    this.updateModifiers();
  }
}
